
@font-face {
    font-family: fabel_font_FilsonPro;
    src: local("fabel_font_FilsonPro"),
      url("fonts/FilsonPro/Mostardesign-FilsonProRegular.otf")
        format("opentype");
  }
  @font-face {
    font-family: Filson Pro Light;
    src: local("Filson Pro Light"),
      url("fonts/FilsonPro/Mostardesign\ -\ FilsonProBook.otf")
        format("opentype");
  }
  @font-face {
    font-family: Filson Pro Black;
    src: local("Filson Pro Black"),
      url("fonts/FilsonPro/Mostardesign\ -\ FilsonProBlack.otf")
        format("opentype");
  }
  @font-face {
    font-family: Filson Pro Bold;
    src: local("Filson Pro Bold"),
      url("fonts/FilsonPro/Mostardesign-FilsonProBold.otf")
        format("opentype");
  }
  @font-face {
    font-family: Filson Pro Heavy;
    src: local("Filson Pro Heavy"),
      url("fonts/FilsonPro/Mostardesign - FilsonProHeavy.otf")
        format("opentype");
  }
  
    @font-face {
    font-family: Filson Pro Regular;
    src: local("Filson Pro Regular"),
      url("fonts/FilsonPro/Mostardesign-FilsonProRegular.otf")
        format("opentype");
  }
  

    @font-face {
    font-family: Sofia Pro Bold;
    src: local("Sofia Pro Bold"),
      url("fonts/SofiaPro/Mostardesign-SofiaPro-Bold.otf")
        format("opentype");
  }

    @font-face {
    font-family: Sofia Pro Regular;
    src: local("Sofia Pro Regular"),
      url("fonts/SofiaPro/Mostardesign-SofiaProRegular.otf")
        format("opentype");
  }

      @font-face {
    font-family: Filson Pro Regular Italic;
    src: local("Filson Pro Regular Italic"),
      url("fonts/FilsonPro/Mostardesign - FilsonProRegular-Italic.otf")
        format("opentype");
  }

      @font-face {
    font-family: Sofia Pro Regular Italic;
    src: local("Sofia Pro Regular Italic"),
      url("fonts/SofiaPro/Mostardesign - SofiaProRegular-Italic.otf")
        format("opentype");
  }


 

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body, html {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  position: relative;
  scrollbar-width: thin;
}