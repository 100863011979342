/* Responsive styles for screens between 320px and 767px */
@media (min-width: 320px) and (max-width: 767px) {
	.login-area {
		width: 95%;
		margin: 0px auto;
		text-align: center;
	}
  .top-header{
	  background-color:#293947;
	  /*background-color:red;*/
	  width:100%;
	  height:auto;
		position:relative;
	  z-index:5;
	}
	
	.top-search{
		width:100%;
		float:none !important;
		text-align:center;
		padding:5px;
	}
	.logo {
		width: 251px;
		float:none !important;
		margin: 0px auto;
		padding:5px;
		text-align:center;
	}
	.mobile-ham-menu{
		position: absolute;
		display: block;
	}
	.mobile-ham-menu img {
		width: 25px;
		cursor: pointer;
	}
	 .profile-area{
		position: absolute;
		top: 18px;
		right: 0px;
		text-align: center;
		width: 69px;
		border: 0px solid red;
	 }
	 .profile-username{
	 	font-size: 12px;
	 }
	
	.left-menu{
		display:none;
		top: 20px;
	}
	
	.right-content{
		float:none;	
	}
	
	.content-top {
		background-color: #0496e3;
		height:auto;
		width: 100%;
		position: relative;
		margin:0px;
		z-index: 2;
	}
	
	.content-top-left, .content-top-right{	
		width:90%;
		text-align:center;
		margin:0px auto;
		padding:10px;
	}
	
	.content-top-left h1 {
    font-family: 'Roboto', sans-serif;
    padding: 0px;
    font-size: 20px;
    color: #FFF;
    font-weight: 700;
    line-height: 30px;
	}
	
	.top-btn a {
    padding: 10px 20px;
    background-color: #2f4050;
    border-radius: 10px;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 12px;
    color: #FFF;
    text-decoration: none;
    float: left;
    display: block;
    margin: 10px 10px 0px 10px;
	}
	
	.right-main-content {
    width: 100%;
    margin: 0px auto;
    margin-top: 30px;
    border: 0px solid green;
    margin-left: 0px;
	}
	
	.boxes-left {
		float:none;
		width: 100%;
		border: 0px solid red;
		display: flex;
		flex-wrap: nowrap;
		flex-direction: column;
		align-content: center;
		align-items: center;
	}
	
	.data-box-01, .data-box-04, .boxes-right {
		width: 95%;
		background-color: #FFF;
		float:none;
		margin:0px auto;
		margin-top:10px;
	}
	
	

	.data-box-top h1, .servicehefte-box-top h1 {
		font-size: 20px;
		padding-left: 10px;
	}
	
	.data-box-02-pending {
		width: 95%;
		height: auto;
		background-color: #ED5564;
		float:none;
		margin-left:0PX;
		padding: 20px 0px;
	}
	
	.data-box-02-pending img{
		width:25%;
		height:25%;
		margin:0px auto;
	}
	
	.data-box-02-congrat h1, .data-box-02-pending h1, .data-box-02-progress h1 {
		font-family: 'Roboto', sans-serif;
		font-weight: 700;
		font-size: 25px;
		color: #FFF;
		margin: 0px;
		padding: 0px;
	}
	
	.data-box-02-pending .pending-count, .data-box-02-progress .pending-count {
		font-size: 80px;
		font-weight: bold;
		font-family: 'Roboto', sans-serif;
		color: #FFF;
	}
	
	.servicehefte-btn {
		padding: 10px 15px;
		background-color: #2f4050;
		border-radius: 10px;
		font-family: 'Roboto', sans-serif;
		font-weight: 400;
		font-size: 14px;
		color: #FFF;
		cursor: pointer;
		text-decoration: none;
		display: block;
		margin: 20px 0px 0px 10px;
	}
	.report-radio-area {
		margin: 0px auto;
		padding: 30px 30px;
		background-color: #FFFFFF;
	}

	.profile-input {
		width: 90%;
		padding: 10px;
		border-radius: 39px;
		border: 3px solid #2374b7;
		outline: none;
		font-size: 15px;
		font-weight: bold;
	}


}

/* Responsive styles for screens between 768px and 991px */
@media (min-width: 768px) and (max-width: 991px) {
  .top-header{
	  background-color:#293947;
	  /*background-color:red;*/
	  width:100%;
	  height:auto;
		position:relative;
	  z-index:5;
	}
	
	.top-search{
		width:100%;
		float:none !important;
		text-align:center;
		padding:5px;
	}
	.logo {
		width: 251px;
		float:none !important;
		margin: 0px auto;
		padding:5px;
		text-align:center;
	}
	.mobile-ham-menu{
		position: absolute;
		display: block;
	}
	.mobile-ham-menu img {
		width: 25px;
		cursor: pointer;
	}
	 .profile-area{
		position: absolute;
		top: 18px;
		right: 0px;
		text-align: center;
		width: 69px;
		border: 0px solid red;
	 }
	 .profile-username{
	 	font-size: 12px;
	 }
	
	.left-menu{
		display:none;
		top: 20px;
	}
	
	.right-content{
		float:none;	
	}
	
	.content-top {
		background-color: #0496e3;
		height:auto;
		width: 100%;
		position: relative;
		margin:0px;
		z-index: 2;
	}
	
	.content-top-left, .content-top-right{	
		width:90%;
		text-align:center;
		margin:0px auto;
		padding:10px;
	}
	
	.content-top-left h1 {
    font-family: 'Roboto', sans-serif;
    padding: 0px;
    font-size: 20px;
    color: #FFF;
    font-weight: 700;
    line-height: 30px;
	}
	
	.top-btn a {
    padding: 10px 20px;
    background-color: #2f4050;
    border-radius: 10px;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 12px;
    color: #FFF;
    text-decoration: none;
    float: left;
    display: block;
    margin: 10px 10px 0px 10px;
	}
	
	.right-main-content {
    width: 100%;
    margin: 0px auto;
    margin-top: 30px;
    border: 0px solid green;
    margin-left: 0px;
	}
	
	.boxes-left {
		float:none;
		width: 100%;
		border: 0px solid red;
		display: flex;
		flex-wrap: nowrap;
		flex-direction: column;
		align-content: center;
		align-items: center;
	}
	
	.data-box-01, .data-box-04, .boxes-right {
		width: 95%;
		background-color: #FFF;
		float:none;
		margin:0px auto;
		margin-top:10px;
	}
	
	

	.data-box-top h1, .servicehefte-box-top h1 {
		font-size: 20px;
		padding-left: 10px;
	}
	
	.data-box-02-pending {
		width: 95%;
		height: auto;
		background-color: #ED5564;
		float:none;
		margin-left:0PX;
		padding: 20px 0px;
	}
	
	.data-box-02-pending img{
		width:25%;
		height:25%;
		margin:0px auto;
	}
	
	.data-box-02-congrat h1, .data-box-02-pending h1, .data-box-02-progress h1 {
		font-family: 'Roboto', sans-serif;
		font-weight: 700;
		font-size: 25px;
		color: #FFF;
		margin: 0px;
		padding: 0px;
	}
	
	.data-box-02-pending .pending-count, .data-box-02-progress .pending-count {
		font-size: 80px;
		font-weight: bold;
		font-family: 'Roboto', sans-serif;
		color: #FFF;
	}
  
}

/* Responsive styles for screens between 992px and 1199px */
@media (min-width: 992px) and (max-width: 1199px) {
 
	
	.content-top {
		  background-color:#0496e3;
		  height:auto;
		  width:100%;
		  position:relative;
		  margin-top:96px;
		  z-index:2;
	}
	
	.content-top-left{
	  float:left;
	  width:40%;
	  margin-left:120px;
	  border:0px solid #F00;
	}
	
	.content-top-right{
	  float:right;
	  width:40%;
	  border:0px solid #F00;
	}
	
	.content-top-left h1 {
    font-family: 'Roboto', sans-serif;
    padding: 0px;
    font-size: 20px;
    color: #FFF;
    font-weight: 700;
    line-height: 30px;
	}
	
	.top-btn a {
    padding: 10px 20px;
    background-color: #2f4050;
    border-radius: 10px;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 12px;
    color: #FFF;
    text-decoration: none;
    float: right;
    display: block;
    margin: 10px 10px 0px 10px;
	}
	
	.right-main-content {
    width: 88%;
    margin: 0px auto;
    margin-top: 30px;
    border: 0px solid green;
    margin-left:110px;
	}
	
	.boxes-left {
		float:none;
		width: 100%;
		border: 0px solid red;
		display: flex;
		flex-wrap: nowrap;
		flex-direction: column;
		align-content: center;
		align-items: center;
	}
	
	.boxes-right {
		width: 100%;
		background-color: #FFF;
		float:none;
		margin:0px auto;
		margin-top:10px;
	}
	
	.data-box-01{
		width: 100%;
		background-color: #FFF;
		float:none;
		margin:0px auto;
		margin-top:10px;
	}
	
	.data-box-04{
		width: 100%;
		background-color: #FFF;
		float:right;
		margin:0px auto;
		margin-top:10px;
	}
	
	.data-box-02-pending {
		width: 100%;
		height: auto;
		background-color: #ED5564;
		float:left;
		margin-left:0px;
		margin-top:10px;
		padding: 20px 0px;
	}
  
}

/* Responsive styles for screens 1200px and above */
@media (min-width: 1200px) {
  
  
}